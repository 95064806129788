<template>
  <div>
    <div class="dynamic-data">
      <div class="little-box">
        <div class="little-box_title">企业库数据总数</div>
        <div class="updata" @click="handUpdata()">刷新</div>
      </div>
      <div
      class="echart-content"
        ref="dynamicData"
      ></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";

export default {
  props: {
    dynamicData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },

  methods: {
    handUpdata(){
      this.initDynamicData()
      setTimeout(() => {
        this.$message.success("刷新成功！");
      }, 2000);
    },
    // 设置动态数据
    initDynamicData() {
      let dataValueSync = this.dynamicData
        .map((item) => item.total)
        .splice(0, 98);
      let dataSync = this.dynamicData.map((item) => item.name).splice(0, 98);
      const charts = echarts.init(this.$refs["dynamicData"]);
      let unitArray =   this.dynamicData.map((item) => {return item.total})
      let option = {
        dataZoom: [
          {
            yAxisIndex: 0,
            show: false, //是否显示滑动条，不影响使用
            type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: 14, // 一次性展示5个
            height: 4,
            orient: "horizontal",
            borderColor: "transparent",
            fillerColor: "rgba(205,205,205,1)",
            zoomLock: false,
            showDataShadow: false, //是否显示数据阴影 默认auto
            backgroundColor: "#fff",
            showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
            realtime: true, //是否实时更新
            filterMode: "filter",
            handleIcon: "circle",

            handleSize: "10%",
            moveHandleSize: 0,
            brushSelect: false, //刷选功能，设为false可以防止拖动条长度改变 ************（这是一个坑）
          },
          {
            type: "inside",
            yAxisIndex: 0,
            zoomOnMouseWheel: false, //滚轮是否触发缩放
            moveOnMouseMove: true, //鼠标滚轮触发滚动
            moveOnMouseWheel: true,
          },
          {
            yAxisIndex: 1,
            show: false, //是否显示滑动条，不影响使用
            type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: 14, // 一次性展示5个
            height: 4,
            orient: "horizontal",
            borderColor: "transparent",
            fillerColor: "rgba(205,205,205,1)",
            zoomLock: false,
            showDataShadow: false, //是否显示数据阴影 默认auto
            backgroundColor: "#fff",
            showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
            realtime: true, //是否实时更新
            filterMode: "filter",
            handleIcon: "circle",
            handleSize: "10%",
            moveHandleSize: 0,
            brushSelect: false, //刷选功能，设为false可以防止拖动条长度改变 ************（这是一个坑）
          },
          {
            type: "inside",
            yAxisIndex: 1,
            zoomOnMouseWheel: false, //滚轮是否触发缩放
            moveOnMouseMove: true, //鼠标滚轮触发滚动
            moveOnMouseWheel: true,
          },
        ],
        backgroundColor: "rgba(205, 221, 243, 0.2)",
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "5%",
          containLabel: true,
        },
 

        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(50,50,50,0.7)",
          borderColor:"rgba(50,50,50,0.1)",
          textStyle: {
            color: "#fff",
          },
          formatter: function (e, e2) {
            return `${e[0].axisValue} <br/>数据总数:<span style="color:#40d5d4;"> ${e[0].data}  </span>  `;
          },
        },

        yAxis: [
          {
            show: true,
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              color: "black",
              fontSize: 15,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#707070",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#707070",
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            data: dataSync,
          },
          {
            show: true,
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              color: "black",
              fontSize: 15,
               formatter: function (value,item) {
                return `${value}`;
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#707070",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#707070",
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            data: unitArray,
          },
          
      
        ],
        xAxis: [
          {
            type: "value",
            min:100,
            max: 61169,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#707070",
                width: 1,
              },
            },
            splitLine: {
              show: true,
            },
            nameTextStyle: {
              color: "red",
            },
            name: "",
          },
        ],
        series: [
          {
            type: "bar",

            label: {
              show: true,
              position: "right",
              color: "#000",
            },
            itemStyle: {
              normal: {
                barBorderColor: "rgba(0,0,0,0)",
                color: "rgba(0,0,0,0)",
              },
              emphasis: {
                barBorderColor: "rgba(0,0,0,0)",
                color: "rgba(0,0,0,0)",
              },
            },
            tooltip: {
              trigger: "none",
            },
            data: [],
          }, 
          {
            type: "bar",
            stack: "1",
            barWidth: 12,
            barBorderRadius: 0,
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: function (params) {
                  return {
                    type: "linear",
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 1,
                        color: "#67adfd", // 100% 处的颜色
                      }
                    ],
                  };
                },
              },
            },
            data: dataValueSync,
          },
        ],
      };
      charts.setOption(option);
    },
  },
};
</script>
<style scoped>
.dynamic-data {
  width: 454px;
  height: 560px;
  border: 1px solid #bbdfff;
  margin-bottom: 30px;
  overflow: auto;
  background-color: rgba(205, 221, 243, 0.2);
}
.little-box {
  border-bottom: 1px solid #C2E3FF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0 10px;
  cursor: pointer;
  padding-bottom: 20px;

}
.little-box_title {
  font-size: 20px;
  background-image: url('https://oss.yanxin7.cn/2023-09-01/a9bb27e389f7c125217fa012d832896c');
  background-repeat: no-repeat;
  background-size: 10px 16px;
  padding-left: 18px;
  background-position-y: 5px;
  
}
.updata{
  background-image: url('https://oss.yanxin7.cn/2023-09-01/0edd23dad209d69facbf6cd6ee5c2d4f');
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding-right: 22px;
  background-position-y: 4px;
  background-position-x: right;
}
/* 修改竖直滚动条样式 */
::-webkit-scrollbar {
  width: 4px !important; /* 滚动条宽度 */
}

/* 竖直滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f5f5f5; /* 轨道背景色 */
  border-radius: 4px; /* 滑块圆角 */
}

/* 竖直滚动条轨道 */
::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.24);
}
.echart-content{
  height: 490px;
}
</style>