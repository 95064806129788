<template>
  <div class="new-data" style="height: 280px">
    <div class="little-box">
      <div class="little-box_title">人员资质</div>
      <div>更多</div>
    </div>
    <!-- 人员资质 -->
    <div class="member-data" ref="memberData"></div>
    <div class="member-num">人数：{{sum}}人</div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { personNum } from "@/api/engineering";
export default {
  data() {
    return {
      incrementData: [],
      sum:7475150,
      percen:0
    };
  },
  watch:{
    percen(curVal,oldVal){
      this.initMemberData()
    }
  },
  mounted() {
    this.getPersonNum();
  },
  methods: {
    async getPersonNum(e){
      try {
        const { data } = await personNum({adCode:e?.value,province:e?.label})
        let newArray = data.filter(item => item.name !== '总数据');
        let totalPerson = data.filter(item => item.name == '总数据');
        console.log('newArray',newArray)
        console.log('totalPerson',totalPerson)
        if (newArray && newArray.length) {
          newArray.forEach(element => {
            element.value = element.count || 0
          });
        } else {
          newArray = [{
            name: '施工人员',
            value: 7865210,
          } ,
        {
          name: '监理人员',
          value: 654101,
        }]
        }
        
        this.echartData =  newArray
        let sum = 0;
        for (let i = 0; i < data.length; i++) {
          sum += data[i].count;
        }
        this.sum = totalPerson[0].count
        this.caluelatePercen(data[0].value)
        this.initMemberData()
      } catch (error) {
        console.log('xxxxxxxx',error)        
      }
     },
     caluelatePercen(e){
      this.percen = ((e/this.sum)*100).toFixed(2)
     },
    initMemberData() {
      let _this = this
      const charts = echarts.init(this.$refs["memberData"]);
      let option = {
        legend: {
          orient: "vertical",
          right: "5",
          top: "40%",
          itemWidth: 18,
          itemHeight: 8,
        },
        tooltip: {
          trigger: 'item'
        },
        color: [
          "#1890FF",
          "#36CBCB",
          "#4ECB73",
          "#FBD437",
          "#7AC0EF",
          "#975FE5",
        ],
        series: [
          {
            // roseType: "radius-area",
            // name: "Access From",
            type: "pie",
            radius: [30, 80, 80, 60],
            center: ["30%", "45%"], // 圆心位置居
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                formatter(param) {
                  return param.name + " (" + param.percent + "%)";
                },
                color: "#282828",
                fontSize: 14,
              },
              labelLine: {
                lineStyle: {
                  color: "#4DA5E0",
                },
              },
            },
            data: this.echartData,
          },
        ],
        // graphic: [
        //   {
        //     type: "text",
        //     left: "23%",
        //     top: "45%",
        //     z: 2,
        //     style: {
        //       textAlign: "center",
        //       text: _this.percen+'%',
        //       fill: "#2288FC",
        //       fontSize: 16,
        //     },
        //   },
        // ],
      };
      charts.on('click', function (e) {
            _this.caluelatePercen(e.data.value)
        });
        charts.on('mousemove', function (e) {
            _this.caluelatePercen(e.data.value)
        });
      charts.setOption(option);
    },
  },
};
</script>
<style scoped>
.dynamic-data {
  width: 320px;
  height: 417px;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #bbdfff;
  margin-bottom: 30px;
  overflow: auto;
}
.little-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0 10px;
  cursor: pointer;
}
.little-box_title {
  font-size: 20px;
  font-weight: bolder;
  position: relative;
}
.little-box_title::before {
  content: ".";
  width: 4px;
  height: 20px;
  background: #2288fc;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-right: 15px;
  color: #2288fc;
}
/* 修改竖直滚动条样式 */
::-webkit-scrollbar {
  width: 4px !important; /* 滚动条宽度 */
}

/* 竖直滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f5f5f5; /* 轨道背景色 */
  border-radius: 4px; /* 滑块圆角 */
}

/* 竖直滚动条轨道 */
::-webkit-scrollbar-track {
  /* background: rgba(250, 250, 250, 0.24); */
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 10px 20px;
}
.member-num {
  position: absolute;
  bottom: 10px;
  left: 40px;
  font-weight: bolder;
}
.member-data {
  margin-top: 20px;
  width: 290;
  height: 210px;
  overflow-x: scroll;
}
</style>