<template >
  <div class="map-data">
    <div ref="charts" style="width: 712px; height: 490px"></div>
  </div>
</template>

<script>
import { infoV2, theme1, theme2, sysCompanyList } from "@/api/engineering";
import {getAppCustomInfo} from '@/utils/public'
import * as echarts from "echarts";
import shengfen from "@/assets/json/china.json"; //地图包
import guangxi from "@/assets/json/province/guangxi.json"; //地图包
import fujian from "@/assets/json/province/fujian.json"; //地图包
import anhui from "@/assets/json/province/anhui.json"; //地图包
import beijing from "@/assets/json/province/beijing.json"; //地图包
import chongqing from "@/assets/json/province/chongqing.json"; //地图包
import gansu from "@/assets/json/province/gansu.json"; //地图包
import guangdong from "@/assets/json/province/guangdong.json"; //地图包
import guizhou from "@/assets/json/province/guizhou.json"; //地图包
import hainan from "@/assets/json/province/hainan.json"; //地图包
import hebei from "@/assets/json/province/hebei.json"; //地图包
import heilongjiang from "@/assets/json/province/heilongjiang.json"; //地图包
import henan from "@/assets/json/province/henan.json"; //地图包
import hubei from "@/assets/json/province/hubei.json"; //地图包
import hunan from "@/assets/json/province/hunan.json"; //地图包
import jiangsu from "@/assets/json/province/jiangsu.json"; //地图包
import jiangxi from "@/assets/json/province/jiangxi.json"; //地图包
import jilin from "@/assets/json/province/jilin.json"; //地图包
import liaoning from "@/assets/json/province/liaoning.json"; //地图包
import neimenggu from "@/assets/json/province/neimenggu.json"; //地图包
import ningxia from "@/assets/json/province/ningxia.json"; //地图包
import qinghai from "@/assets/json/province/qinghai.json"; //地图包
import shandong from "@/assets/json/province/shandong.json"; //地图包
import shanghai from "@/assets/json/province/shanghai.json"; //地图包
import shanxi from "@/assets/json/province/shanxi.json"; //地图包 山西
import shanxi1 from "@/assets/json/province/shanxi1.json"; //地图包 陕西
import taiwan from "@/assets/json/province/taiwan.json"; //地图包
import tianjin from "@/assets/json/province/tianjin.json"; //地图包
import xinjiang from "@/assets/json/province/xinjiang.json"; //地图包
import xizang from "@/assets/json/province/xizang.json"; //地图包
import yunnan from "@/assets/json/province/yunnan.json"; //地图包
import sichuan from "@/assets/json/province/sichuan.json"; //地图包
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    ...mapState("address", ["twoAddress"]),

  },
  data() {
    return {
      isActive: 0,
      showLoginForm: false,
      systemList: [
        {
          name: "投标分析管理体系",
          bgUrl:
            "https://oss.yanxin7.cn/2023-08-24/a7dfbfde8302ad7298542dc8746c3e28",
          url: "/ecosystem/ecosystem?params=1",
        },
        {
          name: "供应链金融生态体系",
          bgUrl:
            "https://oss.yanxin7.cn/2023-08-24/02979393d549be0e126f121a9eae72d5",
          url: "/ecosystem/ecosystem?params=2",
        },
        {
          name: "项目数字化管理",
          bgUrl:
            "https://oss.yanxin7.cn/2023-08-24/651b2c635d652ccb61aa3c85432f8fcf",
          url: "/ecosystem/ecosystem?params=3",
        },
        {
          name: "建筑业增值服务体系",
          bgUrl:
            "https://oss.yanxin7.cn/2023-08-24/aa6ce9d36b3da44fa6d7fe2706b842c9",
          url: "/ecosystem/ecosystem?params=4",
        },
      ],
      usableDate: [],
      dynamicCityName: "全国",
      dynamicCityName2: "全国",
      zZCirtyName: "全国",
      todayTotal: 0,
      totalView: 0,
      totalCompany: 0,
      activeTab: 0,
      menuList: [
        { label: "首页", name: "home" },
        { label: "招标投标", name: "personnel" },
        { label: "金融服务", name: "record" },
        { label: "企业查询", name: "quation" },
        { label: "机械管理", name: "project" },
        { label: "智慧工地", name: "creditscore" },
        { label: "工程咨询", name: "certificate" },
        { label: "CA认证", name: "CA" },
      ],
      proviceList: {
        福建: fujian,
        广西: guangxi,
        新疆: xinjiang,
        安徽: anhui,
        北京: beijing,
        重庆: chongqing,
        甘肃: gansu,
        广东: guangdong,
        贵州: guizhou,
        海南: hainan,
        河北: hebei,
        黑龙江: heilongjiang,
        河南: henan,
        湖北: hubei,
        湖南: hunan,
        江苏: jiangsu,
        江西: jiangxi,
        吉林: jilin,
        辽宁: liaoning,
        内蒙古: neimenggu,
        宁夏: ningxia,
        青海: qinghai,
        山东: shandong,
        上海: shanghai,
        陕西: shanxi1,
        山西: shanxi,
        台湾: taiwan,
        天津: tianjin,
        西藏: xizang,
        云南: yunnan,
        四川: sichuan,
      },
      mapCurProvice: "全国",
      searchShow: false,
      searchShow1: false,
      searchShow2: false,
      options: [],
      charts: null,
      value: "",
      data: [
        { name: "北京" },
        { name: "天津", value: 0 },
        { name: "河北", value: 157 },
        { name: "山西", value: 110 },
        { name: "内蒙古", value: 40 },
        { name: "辽宁", value: 40 },
        { name: "吉林", value: 40 },
        { name: "黑龙江", value: 60 },
        { name: "上海", value: 10 },
        { name: "江苏", value: 60 },
        { name: "浙江", value: 50 },
        { name: "安徽", value: 151 },
        { name: "福建", value: 60 },
        { name: "江西", value: 74 },
        { name: "山东", value: 200 },
        { name: "河南", value: 100 },
        { name: "湖北", value: 40 },
        { name: "湖南", value: 50 },
        { name: "重庆", value: 40 },
        { name: "四川", value: 120 },
        { name: "贵州", value: 135 },
        { name: "云南", value: 90 },
        { name: "西藏", value: 25 },
        { name: "陕西", value: 100 },
        { name: "甘肃", value: 60 },
        { name: "青海", value: 20 },
        { name: "宁夏", value: 110 },
        { name: "新疆", value: 0 },
        { name: "广东", value: 10 },
        { name: "广西", value: 100 },
        { name: "海南", value: 40 },
      ],
      selectValue: "全国",
      machineList: [],
      incrementData: [],
      dynamicDataList: [],
      MembertData: [],
      seriesList: [
        {
          type: "effectScatter",
          coordinateSystem: "geo",
          rippleEffect: {
            brushType: "stroke", // 波纹的绘制方式，可选值有 'stroke' 和 'fill'
            period: 3, //动画时间，值越小速度越快
            scale: 3, //波纹圆环最大限制，值越大波纹越大
          },
          roam: false,
          data: [
            // {
            //   name: "邦建通科技集团股份有限公司(南宁)",
            //   value: [108.40322, 22.81395, 45], //y x
            // },
            // {
            //   name: "邦建通科技集团股份有限公司(厦门)",
            //   value: [118.14326, 24.50269, 45], //y x
            // },
          ],
        },
        {
          selectedMode: true, //取消地图区域点击事件
          geoIndex: 0, //将数据和第0个geo配置关联在一起
          type: "map",
          // data: cityList,
        },
      ],
    };
  },

  mounted() {
    this.getInfoV2();

    this.$nextTick(() => {
      document.title = getAppCustomInfo().title || '首页'
      this.getCompanyList();
      this.init();
    });
    this.$nextTick((res) => {
      setTimeout(() => {
        if (this.twoAddress[0].label !== "全国") {
          this.twoAddress.unshift({
            code: 2,
            label: "全国",
            latitude: 11,
            longitude: 11111,
            newChild: Array(0),
            padCode: null,
            pcode: 1,
            pname: null,
            value: 96851,
          });
        }
      }, 5000);
    });
  },
  methods: {
    init() {
      this.getTheme1();
      this.getTheme2();
      this.setMap(shengfen);
    },
    handleTabClick(tab) {
      let token = this.$store.state.account.token;
      console.log("tap------", tab);
      if (tab.name == "quation") {
        this.toPath("/dataCenter/dataCenter");
      }
      if (tab.name == "certificate") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/engineering/index");
      }

      if (tab.name == "personnel") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/recruit/index");
      }
      if (tab.name == "record") {
        if (window.location.origin.includes("localhost")) {
          window.open("https://dev.yanxin7.cn" + "/bh_web/#/")
          return
        }
        window.open(window.location.origin + "/bh_web/#/")
        return
      }

      let theUrlPrefix = "https://api.yanxin7.cn";
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      } else if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }

      if (tab.name == "project") {
        console.log("点击数字机械");
        const w = window.open("about:blank");
        // w.location.href = "https://www.bangjiantong.com/pc/mechanics.html";
        w.location.href =
          theUrlPrefix + `/erp/#/machine_login?token=${token}&loginType=7`;
      }
      if (tab.name == "creditscore") {
        console.log("点击智慧工地");
        // let url=`${window.location.origin}/erp/#/site_login?token=${token}&loginType=6`
        const w = window.open("about:blank");
        // w.location.href = theUrlPrefix + "/erp/#/smartSite/home";
        w.location.href =
          theUrlPrefix + `/erp/#/site_login?token=${token}&loginType=6`;
      }
    },
    async getInfoV2(e) {
      let params = e == "全国" ? "" : e;
      try {
        const { data } = await infoV2({
          province: params,
        });
        this.dynamicDataList = data.filter((item) => item.classify == 2);
        this.incrementData = data.filter((item) => item.classify == 1);
        this.MembertData = data.filter((item) => item.classify == 3);
        console.log("dynamicDataList", this.dynamicDataList);

        setTimeout(() => {
          this.$refs.dynamicDatas.initDynamicData();
          this.$refs.dynamicDatas2.initDynamicData();
        }, 1000);
      } catch (error) {
        console.log("获取动态数据报错", error);
      }
    },
    // 获取公司列表
    getCompanyList() {
      sysCompanyList({ current: 1, size: 1000, provinceName: "福建省" })
        .then((res) => {
          console.log("公司列表---》", res.data.records);
          let { records } = res.data;
          let usableDate = records.filter((item) => item.y && item.x);
          this.usableDate = usableDate;
        })
        .catch((err) => {
          console.log("获取公司列表错误", err);
        });
    },
    getCurCityLCompanyListInfo(e) {
      let companyList = [];
      let currentProviceList = this.usableDate.filter((item) =>
        item.provinceName.includes(e.name)
      );
      currentProviceList.forEach((item) => {
        let params = {
          name: "",
          value: [0, 0, 45],
        };
        params.name = item.compName;
        params.value[0] = item.y;
        params.value[1] = item.x;
        companyList.push(params);
      });
      this.seriesList[0].data = companyList;
    },
    // 获取当日访问数据
    async getTheme1() {
      try {
        const { data } = await theme1({});
        this.todayTotal = data.todayTotal;
        this.totalView = data.total;
      } catch (error) {
        console.log("err", error);
      }
    },

    async getTheme2() {
      try {
        const { data } = await theme2({});
        this.totalCompany = data.total;
      } catch (error) {
        console.log("err", error);
      }
    },
    // 初始化地图
    initCharts() {
      let _this = this;
      this.charts = echarts.init(this.$refs["charts"]);
      const option = {
        // 背景颜色
        backgroundColor: "#fff",
        // 提示浮窗样式
        tooltip: {
          show: true,
          trigger: "item", //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          alwaysShowContent: true,
          backgroundColor: "#fff",
          borderColor: "rgba(0, 0, 0, 0.16);",
          triggerOn: "mousemove",
          enterable: true, //鼠标是否可进入提示框浮层中
          textStyle: {
            fontSize: "12",
            overflow: "break",
          },
          formatter: function (params) {
            let str = "";
            // if(params.name == '广西' || params.name == '南宁市'){
            //     str='邦建通科技集团股份有限公司(南宁)'
            // }else{
            str = params.name;
            // }
            return str;
          },
        },
        visualMap: {
          //分段型视觉映射组件
          show: false, //
          type: "piecewise",
          left: 50,
          bottom: 50,
          showLabel: false,
          itemWidth: 10,
          itemHeight: 10,
          inverse: false,
          inRange: {
            color: ["#BBDFFF"], // 设置默认的分段颜色
          },
          // lt:小于; lte:小于等于; gt:大于; gte:大于等于;
          pieces: [
            {
              lte: 1,
              label: "",
              color: "#BBDFFF",
            },

            {
              gt: 30,
              label: ">30ms",
              color: "#5693F7",
            },
          ],
        },
        // 地图配置
        geo: {
          map: "中国",
          aspectScale: 0.75, //长宽比,0.75的含义为宽是高的0.75,假如高为100,宽就只有75;0.5的含义就是宽只有高的一半,假如高为100,宽就只有50
          zoom: 1.25, //视觉比例大小,1.2即为原有大小的1.2倍
          maxZoom: 2,
          roam: false, //是否开启鼠标缩放和平移漫游。默认不开启。可以不用设置,如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。
          top: "10%",
          scaleLimit: {
            //所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 1, //最小的缩放值
            max: 1.25, //最大的缩放值
          },
          // left:"45%",
          label: {
            // 通常状态下的样式
            normal: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            // 鼠标放上去的样式
            emphasis: {
              textStyle: {
                color: "#fff",
              },
            },
          },
          // 地图区域的样式设置
          itemStyle: {
            normal: {
              borderColor: "rgba(0, 0, 0, 0.1)",
              borderWidth: 1,
            },
            // 鼠标放上去高亮的样式
            emphasis: {
              // areaColor: "rgba(0, 0, 0, 0.1)",
              borderWidth: 0,
            },
          },

          // 隐藏南海诸多
          //   regions: [
          //   {
          //     name: "南海诸岛",
          //     itemStyle: {
          //       show:false,
          //       // 隐藏地图
          //       normal: {
          //         opacity: 0, // 为 0 时不绘制该图形
          //       }
          //     },
          //     label: {
          //       show: false // 隐藏文字
          //     }
          //   }
          // ]
        },
        series: _this.seriesList,
      };
      var timeFn = null;
      this.charts.setOption(option);
      // 鼠标移入
      this.charts.on("mouseover", function () {
        // console.log("mouseover"1111);
        //取消鼠标移入地图区域高亮
        _this.charts.dispatchAction({
          type: "legendUnSelect",
        });
      });
      // 单击
      this.charts.on("click", function (e) {
        _this.$message({
          message: "双击地图可返回上一级",
          type: "success",
        });
        //鼠标点击取消高亮
        _this.charts.dispatchAction({
          type: "legendUnSelect",
        });
        clearTimeout(timeFn);
        timeFn = setTimeout(function () {
          _this.getCurCityLCompanyListInfo(e);
          console.log('xx', e)
          if (_this.proviceList[e.name]) {
            _this.getAdcode(e.name);
            _this.getInfoV2(e.name);
            _this.setMap(_this.proviceList[e.name]);
            _this.mapCurProvice = e.name;
            _this.charts.off("click");
            console.log("有数据");
          } else {
            // _this.$message({
            //   message: "没有下一级了",
            //   type: "error",
            // });
          }
        }, 250);
      });

      // 双击
      this.charts.on("dblclick", function () {
        clearTimeout(timeFn);
        location.reload(); // 地图多次渲染未解决，先强制刷新页面
        _this.charts.clear();
        _this.setMap(shengfen);
        _this.mapCurProvice = "全国";
      });
    },
    getAdcode(e) {
      console.log("ee", e);
      console.log("xx", this.twoAddress);
      let adcodeItem = this.twoAddress.filter((item) => {
        return item.label.includes(e);
      });
      console.log("adcodeItem", adcodeItem);
      if (adcodeItem.length > 0) {
        // this.$refs.personDta.getPersonNum(adcodeItem[0].value);
        this.$refs.biddingDatas.biddingCount(adcodeItem[0].value);
      }
    },
    leftDynamicItem(e) {
      this.getInfoV2(e);
    },
    setMap(e) {
      console.log('eee', e)

      if (this.charts) {
        this.charts.clear();
        this.charts.off("click");
      } else {
      }
      // 为 china 时会显示南海诸岛
      echarts.registerMap("中国", e);
      this.initCharts();
    },

    // 切换省份
    handMap(e) {
      console.log("地图选中省市", e);
      if (e == "全国") return location.reload();
      if (!e) return;
      let proviceItem = this.twoAddress.filter((item) => item.label == e);
      // this.$refs.personDta.getPersonNum(proviceItem[0].value);
      this.$refs.biddingDatas.biddingCount(proviceItem[0].value);
      console.log("proviceItem", proviceItem);
      this.getInfoV2(e);
      let provice = e.substring(0, 2);
      this.getCurCityLCompanyListInfo({ name: provice });
      if (this.proviceList[provice]) {
        this.setMap(this.proviceList[provice]);
        //
      } else {
        this.$message({
          message: "该区域没有数据",
          type: "error",
        });
      }
    },
    // 清空全国数据dynamicCityName
    ClearDyCity() {
      this.dynamicCityName = "全国";
    },
    // 清空全国数据
    ClearZZCity() {
      this.zZCirtyName = "全国";
    },
    ClearMapCity() {
      this.mapCurProvice = "全国";
      location.reload(); // 地图多次渲染未解决，先强制刷新页面
    },
    handSystemItem(item, index) {
      if (!this.token) {
        this.showLoginForm = true;
        return;
      }
      let vRouter = this.$router.resolve({
        path: item.url,
        params: {},
      });
      window.open(vRouter.href, "_blank");
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      // this.$router.replace(toPage);
      console.log("handleLoginSuccess-->toPage=" + redirectPath);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      //   this.activeTab = "websiteIndex";
      //   if (this.redirectPath != "/") {
      //     this.redirectPath = "/websiteIndex";
      //     this.$router.push({ path: this.redirectPath });
      //   }
    },
  },
};
</script>
<style>
.select-city {
  position: absolute;
  z-index: 9;
  margin-top: 40px;
  transform: translateX(-50%);
  /* 修改下拉箭头的颜色和大小 */

  border-radius: 18rpx;
  /* overflow: hidden; */
}

.select-city .el-input--mini .el-input__inner {
  height: 28px;
  line-height: 28px;
  border-radius: 18px;
}

.el-select .el-input {
  width: 150px;
}
</style>
<style scoped lang="less">
/* 头部背景图 */

.main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}

.data-center {
  display: flex;
  justify-content: space-between;
  margin-bottom: 97px;
  width: 1520px;
  box-sizing: border-box;
  overflow: hidden;
}

.new-data {
  width: 320px;
  height: 244px;
  opacity: 1;
  border: 1px solid #bbdfff;
  position: relative;
}

.member-num {
  position: absolute;
  bottom: 10px;
  left: 40px;
  font-weight: bolder;
}

.map-box {
  position: relative;
}

.map-data {
  margin-top: 170px;
  width: 722px;

  position: relative;
  margin-bottom: 150px;
}

.second-page {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second-header {
  display: flex;
  justify-content: center;
  margin-bottom: 54px;
  color: white;
  text-align: center;
}

.second-title {
  font-weight: bolder;
  font-size: 32px;
  text-align: center;
}

.fz-12 {
  font-size: 12px;
  margin-top: 15px;
  margin-left: 20px;
}

.second-content {
  width: 1520px;
  display: flex;
  justify-content: space-between;
}

.second-left {
  width: 425px;
  height: 859px;
  margin-right: 125px;
  background-image: url("https://oss.yanxin7.cn/2023-07-05/41e5b471652eaa689d3a855314108739");
  background-repeat: no-repeat;
  background-size: 425px 859px;
}

.mb-20 {
  margin-bottom: 20px;
}

.second-right {
  width: 600px;
  height: 859px;
  padding: 20px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.second-right-box {
  width: 560px;
  height: 829px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  overflow-x: hidden;
}

.second-right::-webkit-scrollbar {
  width: 1px;
  /* 滚动条宽度 */
}

.second-right:hover {}

.c-yellow {
  color: #ffd339;
  font-weight: bolder;
}

.machine-item {
  width: 517px;
  height: 40px;
  background: rgba(124, 169, 231, 0.3);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.machine-item:nth-child(odd) {
  background: none;
}

.machine-item:hover {
  color: #2288fc;
  background-color: white;
}

/* 修改竖直滚动条样式 */
::-webkit-scrollbar {
  width: 4px !important;
  /* 滚动条宽度 */
}

/* 竖直滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  /* 轨道背景色 */
  border-radius: 4px;
  /* 滑块圆角 */
}

/* 竖直滚动条轨道 */
::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.24);
}

.add-data {
  width: 745px;
  height: 1030px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
  background-color: #2e8dfb;
}

.data-header {
  text-align: center;
  justify-content: center;
  width: 745px;
  height: 120px;
  background: rgba(250, 250, 250, 0.34);
  border-radius: 10px 10px 0px 0px;
  background-image: url("https://oss.yanxin7.cn/2023-07-05/d520046b09c8e046d561e214c4c4d3a2");
  background-repeat: no-repeat;
  background-size: 204px 116px;
  background-position: 396px 0;
  display: flex;
  padding-top: 36px;
  padding-left: 20px;
  position: relative;
}

.second-header-data::before {
  content: ".";
  width: 4px;
  height: 20px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-right: 20px;
  margin-top: 13px;
}

.little-quanguo {
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50px;
  right: 35px;
}

.data-boxs {
  width: 745px;
  overflow: auto;
  height: 900px;
  color: white;
}

.data-item {
  height: 50px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 40px;
}

.b-line {
  margin: 0 20px;
  border-bottom: 1px solid rgb(247, 227, 227);
}

.data-item:last-child {
  border-bottom: none;
}

.flex-center {
  display: flex;
  align-items: center;
}

.add-icon {
  width: 8px;
  height: 8px;
  background: #ffd339;
  opacity: 1;
  border-radius: 50%;
  margin-right: 20px;
}

.data-item:hover {
  background-color: #2288fc;
}

.align-center {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 25px;
}

.align-center2 {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 15px;
  font-weight: bolder;
  letter-spacing: 3px;
}

.menu-item .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: rgba(112, 154, 233, 0.3) !important;
}

::v-deep #content.el-tabs--border-card {
  border: none;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  background-color: #0460e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  color: white;
  background-color: #0662e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  margin-top: 0px;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  height: 40px;
}

::v-deep .el-tabs__nav.is-stretch>* {
  flex: 1;
  text-align: center;
  width: 170px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  font-weight: bold;
}

.min-w {
  min-width: 1320px;
}

::v-deep .el-header {
  padding: 10px 0px 0px 0px;
}

/* 菜单箭头 */
::v-deep .el-tabs__nav-next,
::v-deep .el-tabs__nav-prev {
  position: absolute;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  color: #ffffff;
}

::v-deep .el-tabs__nav-next.is-disabled,
::v-deep .el-tabs__nav-prev.is-disabled {
  color: #ffffff;
}

::v-deep .el-tabs--border-card>.el-tabs__header {
  /* background-color: #F5F7FA; */
  /* border-bottom: 1px solid #E4E7ED; */
  margin: 0;
}

::v-deep .el-tabs--bottom .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: 0px;
}

.menu-item .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: rgba(112, 154, 233, 0.3) !important;
}

.el-carousel__button {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.info-left_video .el-carousel__arrow {
  font-size: 30px !important;
  background-color: rgba(31, 45, 61, 0.1);
  color: white;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header {
  background-color: transparent;
}

::v-deep #content.el-tabs--border-card {
  border: none;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  color: white;
  background-color: #0662e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  margin-top: 0px;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  height: 40px;
}

::v-deep .el-tabs__nav.is-stretch>* {
  flex: 1;
  text-align: center;
  width: 170px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  font-weight: bold;
}

.nav-width {
  height: 40px;
  width: 100%;
  margin: 0 auto;
  background-color: #013c8233;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  background-color: transparent;
}

.el-tabs--border-card {
  background: transparent;
  box-shadow: none;
}

.system-list {
  width: 1520px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.system-item {
  cursor: pointer;
  width: 370px;
  height: 250px;
  background: #ffffff;
  box-shadow: 0px 3px 20px 1px rgba(183, 183, 183, 0.16);
  border-radius: 2px 2px 2px 2px;
  margin-bottom: 40px;
}

.item-text {
  font-size: 26px;
  line-height: 80px;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: white !important;
  color: #007ef2 !important;
}

.is_active {
  color: #2288fc;
}</style>

