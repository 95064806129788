<template>
  <div>
    <div class="dynamic-data">
      <div class="little-box">
        <div class="little-box_title">今年用户访问量</div>
        <div class="updata" @click="handUpdata()">刷新</div>
      </div>
      <div
        class="dynamicData"
        ref="chartRef"
      ></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { getToYearData } from "@/api/engineering";
export default {
  // props: {
  //   dataList: {
  //     type: Array,
  //     default: () => [],
  //   },
  // },
  data() {
    return {
      mainChart: null,
      sourceList: [{}, {}],
      dataList: [],
      year:2024
    };
  },
  beforeMount() {},
  mounted() {
    let date = new Date();
    this.year = date.getFullYear()
    this.getToYearData();
  },
  methods: {
    async getToYearData() {
      try {
        const { data } = await getToYearData({});
        this.dataList = data.monthList;
        this.setupChart();
      } catch (error) {
        console.log("获取访问数据错误", error);
      }
    },
    handUpdata() {
      this.dataList = [];
      this.setupChart();
      this.getToYearData();
      setTimeout(() => {
        this.$message.success("刷新成功！");
      }, 2000);
    },
    fetchData(province, year) {
      let params = {
        province: province,
        year: year,
      };
      let that = this;
      that.mainChart?.showLoading("default", { text: "加载中" });
      browsePerformance(params)
        .then((res) => {
          that.sourceList = res.data || [];
          that.setupChart();
        })
        .finally(() => {
          that.mainChart?.hideLoading();
        });
    },
    setupChart() {
      let that = this
      let myChart = echarts.init(this.$refs["chartRef"]);
      if (!myChart) {
        return;
      }
      this.mainChart = myChart;
      let xLabel = this.dataList.map((x) => x.month); //['7/13','7/14', '7/15', '7/16', '7/17']
      let res1 = this.dataList.map((x) => x.total); // [22553, 3233, 4200, 4180, 2199];
      console.log("xLabel", xLabel);
      let option = {
        backgroundColor: "rgba(205, 221, 243, 0.2)",
        grid: {
          top: "15%",
          left: "15%",
          right: "10%",
          bottom: "15%",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(50,50,50,0.7)",
          borderColor:"rgba(50,50,50,0.1)",
          textStyle: {
            color: "#fff",
          },
          formatter: function (e, e2) {
            console.log('xxx',e[0].axisValue)
            return `${that.year}-${e[0].axisValue.slice(0, -1)} <br/>用户访问量:<span style="color:#40d5d4;"> ${e[0].data}  </span>  `;
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: "#rgb(77,83,141)",
                width: 1,
              },
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置
              textStyle: {
                padding: 16,
                fontSize: 14,
              },
              formatter: function (data) {
                return data;
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#888",
              },
            },
            axisTick: {
              show: false,
            },
            data: xLabel,
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              color: "red",
              fontSize: 16,
              padding: 10,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(163,168,173,.3)",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(163,168,173,.3)",
                width: 1,
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#888",
                padding: 8,
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: "line",
            symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            showSymbol: true,
            smooth: true, //平滑
            symbolSize: 10,
            label: {
              show: false,
              position: "top",
              color: "rgb(48,213,212)",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(63,204,211,0.2)",
                },
                {
                  offset: 1,
                  color: "rgba(63,204,211,0.8)",
                },
              ]),
            },
            lineStyle: {
              normal: {
                width: 1,
                color: "rgb(48,213,212)", // 线条颜色
              },
            },
            itemStyle: {
              color: "rgb(48,213,212)",
            },
            tooltip: {
              show: true,
            },
            data: res1,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style scoped>
.dynamic-data {
  width: 454px;
  height: 560px;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #bbdfff;
  margin-bottom: 30px;
  overflow:hidden;
  background-color: rgba(205, 221, 243, 0.2);

}
.little-box {
  border-bottom: 1px solid #c2e3ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0 10px;
  cursor: pointer;
  padding-bottom: 20px;
}
.little-box_title {
  font-size: 20px;
  background-image: url("https://oss.yanxin7.cn/2023-09-01/a9bb27e389f7c125217fa012d832896c");
  background-repeat: no-repeat;
  background-size: 10px 16px;
  padding-left: 18px;
  background-position-y: 5px;
}
.updata {
  background-image: url("https://oss.yanxin7.cn/2023-09-01/0edd23dad209d69facbf6cd6ee5c2d4f");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding-right: 22px;
  background-position-y: 4px;
  background-position-x: right;
}
/* 修改竖直滚动条样式 */
::-webkit-scrollbar {
  width: 4px !important; /* 滚动条宽度 */
}

/* 竖直滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f5f5f5; /* 轨道背景色 */
  border-radius: 4px; /* 滑块圆角 */
}

/* 竖直滚动条轨道 */
::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.24);
}
.dynamicData{
  height:490px;
}
</style>