<template>
  <div>
    <div class="dynamic-data">
      <div class="little-box">
        <div class="little-box_title">增值数据</div>
        <div>更多</div>
      </div>
      <div style="height: 40px"></div>
      <div class="dynamicData" ref="dynamicData"></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";

export default {
  props: {
    dynamicData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },

  methods: {
    // 设置动态数据
    initDynamicData() {
      let dataValueSync = this.dynamicData
        .map((item) => item.count)
        .splice(0, 98);
      let dataSync = this.dynamicData.map((item) => item.name).splice(0, 98);
      let unitArray = this.dynamicData.map((item) => {
        return item.count + item.unit;
      });
      const charts = echarts.init(this.$refs["dynamicData"]);
      let option = {
        dataZoom: [
          {
            yAxisIndex: 0,
            show: false, //是否显示滑动条，不影响使用
            type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: 11, // 一次性展示5个
            height: 4,
            orient: "horizontal",
            borderColor: "transparent",
            fillerColor: "rgba(205,205,205,1)",
            zoomLock: false,
            showDataShadow: false, //是否显示数据阴影 默认auto
            backgroundColor: "#fff",
            showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
            realtime: true, //是否实时更新
            filterMode: "filter",
            handleIcon: "circle",

            handleSize: "10%",
            moveHandleSize: 0,
            brushSelect: false, //刷选功能，设为false可以防止拖动条长度改变 ************（这是一个坑）
          },
          {
            type: "inside",
            yAxisIndex: 0,
            zoomOnMouseWheel: false, //滚轮是否触发缩放
            moveOnMouseMove: true, //鼠标滚轮触发滚动
            moveOnMouseWheel: true,
          },
          {
            yAxisIndex: 1,
            show: false, //是否显示滑动条，不影响使用
            type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: 11, // 一次性展示5个
            height: 4,
            orient: "horizontal",
            borderColor: "transparent",
            fillerColor: "rgba(205,205,205,1)",
            zoomLock: false,
            showDataShadow: false, //是否显示数据阴影 默认auto
            backgroundColor: "#fff",
            showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
            realtime: true, //是否实时更新
            filterMode: "filter",
            handleIcon: "circle",

            handleSize: "10%",
            moveHandleSize: 0,
            brushSelect: false, //刷选功能，设为false可以防止拖动条长度改变 ************（这是一个坑）
          },
          {
            type: "inside",
            yAxisIndex: 1,
            zoomOnMouseWheel: false, //滚轮是否触发缩放
            moveOnMouseMove: true, //鼠标滚轮触发滚动
            moveOnMouseWheel: true,
          },
        ],
        backgroundColor: "#fff",
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "5%",
          containLabel: true,
        },
        tooltip: {
          show: false,
          formatter: "{b}<br/>{c}%",
          textStyle: {
            fontWeight: "bold",
            fontSize: 16,
          },
        },
        yAxis: [
          {
            show: true,
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              color: "black",
              fontSize: 15,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#707070",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#707070",
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            // data: [
            //   "机械管理",
            //   "机械商城",
            //   "配件商城",
            //   "机械维修",
            //   "全屋家居",
            //   "定制市场",
            //   "厨房定制",
            //   "智慧工地",
            // ],
            data: dataSync,
          },
          {
            show: true,
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              color: "black",
              fontSize: 15,
              formatter: function (value) {
                return `${value}`;
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#707070",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#707070",
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            data: unitArray,
          },
        ],
        xAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
            max: 100,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#707070",
                width: 1,
              },
            },
            splitLine: {
              show: false,
            },
            nameTextStyle: {
              color: "red",
            },
            name: "",
            axisLabel: {
              show: false,
              inside: false,
              textStyle: {
                color: "#fff",
                fontSize: 17,
              },
              interval: 0,
              formatter: "{value}%",
            },
          },
        ],
        series: [
          {
            type: "bar",

            label: {
              show: true,
              position: "right",
              color: "#000",
            },
            itemStyle: {
              normal: {
                barBorderColor: "rgba(0,0,0,0)",
                color: "rgba(0,0,0,0)",
              },
              emphasis: {
                barBorderColor: "rgba(0,0,0,0)",
                color: "rgba(0,0,0,0)",
              },
            },
            tooltip: {
              trigger: "none",
            },
            data: [],
          }, //设置两个柱状图进行重叠，第一层柱子设置透明度,由此来实现柱子与坐标轴之间的距离  stack:''设置重叠效果
          {
            type: "bar",
            stack: "1",
            barWidth: 12,
            barBorderRadius: 0,
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: function (params) {
                  return {
                    type: "linear",
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0.9,
                        color: "#fbfdff", // 100% 处的颜色
                      },
                      {
                        offset: 0,
                        color: "#3c96fc", // 0% 处的颜色
                      },
                    ],
                  };
                },
              },
            },
            data: dataValueSync,
          },
        ],
      };
      charts.setOption(option);
    },
  },
};
</script>
<style scoped>
.dynamic-data {
  height: 554px;
  width: 320px;
  border: 1px solid #bbdfff;
  margin-bottom: 30px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.little-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0 10px;
  top: 0px;
  position: absolute;
  z-index: 9999;
  width: 100%;
}

.little-box_title {
  font-size: 20px;
  font-weight: bolder;
  position: relative;
}

.little-box_title::before {
  content: ".";
  width: 4px;
  height: 20px;
  background: #2288fc;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-right: 15px;
  color: #2288fc;
}

/* 修改竖直滚动条样式 */
::-webkit-scrollbar {
  width: 4px !important;
  /* 滚动条宽度 */
}

/* 竖直滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  /* 轨道背景色 */
  border-radius: 4px;
  /* 滑块圆角 */
}

/* 竖直滚动条轨道 */
::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.24);
}

.dynamicData {
  width: 320px;
  height: 514px;
}</style>