<template >
  <div class="main-box">
    <!-- 头部 -->
    <headerMenu></headerMenu>
    <!-- 访问数据 -->
    <visitData></visitData>
    <div style="margin: 0 auto 20px; width: 1520px;">
      <h1>全国数据统计</h1>
    </div>
    <div class="data-center">
      <div>
        <dynamicData ref="dynamicDatas" :dynamicData="dynamicDataList"></dynamicData>
        <biddingData ref="biddingDatas"></biddingData>
      </div>
      <div class="map-box">
        <el-select @clear="ClearMapCity" clearable size="mini" class="select-city" @change="handMap"
          v-model="mapCurProvice" placeholder="请选择">
          <el-option v-for="item in twoAddress" :key="item.code" :label="item.label" :value="item.label">
          </el-option>
        </el-select>
        <div class="map-data">
          <div ref="charts" style="width: 712px; height: 490px"></div>
        </div>
        <div class="align-center">
          <el-image style="height: 24px; width: 24px"
            src="https://oss.yanxin7.cn/2023-07-05/0a6fcbdc2de303dd41208041e52a0640"></el-image>
          &nbsp;{{ mapCurProvice }}
        </div>
      </div>
      <div>
        <addreData ref="dynamicDatas2" :dynamicData="incrementData"></addreData>
        <personDta ref="personDta"></personDta>
      </div>
    </div>
    <!-- <div class="system-list">
      <div :style="{ 'background-image': 'url(' + item.bgUrl + ')' }" class="system-item"
        @click="handSystemItem(item, index)" :class="isActive == index && 'is_active'" v-for="(item, index) in systemList"
        :key="index">
        <div class="item-text">{{ item.name }}</div>
      </div>
    </div> -->
    <!-- 第二页 -->
    <div class="second-page">
      <div class="second-header">数据统计</div>
      <div class="second-content">
        <div class="flex-row-between mb-20">
          <div class="second-left">
            <el-image style="min-width: 80px; height: 80px; margin-right: 20px"
              src="https://oss.yanxin7.cn/2023-09-01/36465a4911c34b502c204cccdeeb24a6"></el-image>
            <div class="flex-row-start">
              <div class="second-title mb-10">全端数据资产管理</div>
              <div class="second-sub">
                网站、APP、小程序等各端数据一站式管理，实现一键管理
              </div>
            </div>
          </div>
          <div class="flex-row-between">
            <div class="box1">多端采集</div>
            <div class="box2">跨端融合</div>
            <div class="box3">用户分群</div>
          </div>
          <div class="second-left">
            <el-image style="min-width: 80px; height: 80px; margin-right: 20px"
              src="https://oss.yanxin7.cn/2023-09-01/e99468d9bc6e411e84b906962338e68a"></el-image>
            <div class="flex-row-start">
              <div class="second-title mb-10">多维智能数据分析</div>
              <div class="second-sub">
                深度洞察企业客户需求，助力企业数字化转型
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row-between">
          <enterpriseData ref="enterpriseData" :dynamicData="companyData"></enterpriseData>
          <!-- .replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                  return s + ",";
                }) -->
          <div class="enterprise-num">
            <div class="line-height20">企业总数</div>
            <div class="enterprise-nums">
              <span class="fz-50" v-if="totalCompanyNum">{{
                totalCompanyNum
              }}</span><span class="fz-30">家</span>
            </div>
          </div>

          <visitDataChart ref="enterpriseDatax" :dataList="yearVisitData"></visitDataChart>
        </div>
      </div>
    </div>
    <!-- 需求变更，隐藏会员信息 by liyibiao 20240924 -->
    <!-- <memberData :memberList="MembertData"></memberData> -->
    <login-form v-if="showLoginForm" :routeRedirect="'/dataCenter/dataCenter'" @loginSuccess="handleLoginSuccess"
      @close="handleLoginClose" />
  </div>
</template>

<script>
import enterpriseData from "./components/enterprise-data.vue";
import visitDataChart from "./components/visit-data.vue";

import LoginForm from "@/components/login-form";
import mapChart from "./components/mapCharts.vue";
import {
  infoV2,
  theme1,
  theme2,
  sysCompanyList,
  urlBrowseEntStatTheme1
} from "@/api/engineering";
import {getAppCustomInfo} from '@/utils/public'
import * as echarts from "echarts";
import shengfen from "@/assets/json/china.json"; //地图包
import visitData from "@/components/visit-data/visit-data.vue";
import headerMenu from "@/components/header-menu/header-menu.vue";
import memberData from "./components/member-data.vue";
import dynamicData from "./components/dynamics-data.vue";
import addreData from "./components/addre-data.vue";
import personDta from "./components/person-data.vue";
import biddingData from "./components/bidding-data.vue";
import guangxi from "@/assets/json/province/guangxi.json"; //地图包
import fujian from "@/assets/json/province/fujian.json"; //地图包
import anhui from "@/assets/json/province/anhui.json"; //地图包
import beijing from "@/assets/json/province/beijing.json"; //地图包
import chongqing from "@/assets/json/province/chongqing.json"; //地图包
import gansu from "@/assets/json/province/gansu.json"; //地图包
import guangdong from "@/assets/json/province/guangdong.json"; //地图包
import guizhou from "@/assets/json/province/guizhou.json"; //地图包
import hainan from "@/assets/json/province/hainan.json"; //地图包
import hebei from "@/assets/json/province/hebei.json"; //地图包
import heilongjiang from "@/assets/json/province/heilongjiang.json"; //地图包
import henan from "@/assets/json/province/henan.json"; //地图包
import hubei from "@/assets/json/province/hubei.json"; //地图包
import hunan from "@/assets/json/province/hunan.json"; //地图包
import jiangsu from "@/assets/json/province/jiangsu.json"; //地图包
import jiangxi from "@/assets/json/province/jiangxi.json"; //地图包
import jilin from "@/assets/json/province/jilin.json"; //地图包
import liaoning from "@/assets/json/province/liaoning.json"; //地图包
import neimenggu from "@/assets/json/province/neimenggu.json"; //地图包
import ningxia from "@/assets/json/province/ningxia.json"; //地图包
import qinghai from "@/assets/json/province/qinghai.json"; //地图包
import shandong from "@/assets/json/province/shandong.json"; //地图包
import shanghai from "@/assets/json/province/shanghai.json"; //地图包
import shanxi from "@/assets/json/province/shanxi.json"; //地图包 山西
import shanxi1 from "@/assets/json/province/shanxi1.json"; //地图包 陕西
import taiwan from "@/assets/json/province/taiwan.json"; //地图包
import tianjin from "@/assets/json/province/tianjin.json"; //地图包
import xinjiang from "@/assets/json/province/xinjiang.json"; //地图包
import xizang from "@/assets/json/province/xizang.json"; //地图包
import yunnan from "@/assets/json/province/yunnan.json"; //地图包
import sichuan from "@/assets/json/province/sichuan.json"; //地图包
import aomen from "@/assets/json/province/aomen.json"; //地图包
import xianggang from "@/assets/json/province/xianggang.json"; //地图包
import zhejiang from "@/assets/json/province/zhejiang.json"; //地图包

import { mapState } from "vuex";
export default {
  components: {
    memberData,
    dynamicData,
    biddingData,
    addreData,
    personDta,
    visitData,
    headerMenu,
    LoginForm,
    visitDataChart,
    mapChart,
    zhejiang,
    enterpriseData,
  },
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    ...mapState("address", ["twoAddress"]),
    // routeRedirect() {
    //   return this.$route.query.redirect;
    // },
  },
  data() {
    return {
      companyData: [],
      isActive: 0,
      showLoginForm: false,
      systemList: [
        {
          name: "投标分析管理体系",
          bgUrl:
            "https://oss.yanxin7.cn/2023-08-24/a7dfbfde8302ad7298542dc8746c3e28",
          url: "/subwebsite/ecosystem?params=0",
        },
        {
          name: "供应链金融生态体系",
          bgUrl:
            "https://oss.yanxin7.cn/2023-08-24/02979393d549be0e126f121a9eae72d5",
          url: "/subwebsite/ecosystem?params=1",
        },
        {
          name: "项目数字化管理",
          bgUrl:
            "https://oss.yanxin7.cn/2023-08-24/651b2c635d652ccb61aa3c85432f8fcf",
          url: "/subwebsite/ecosystem?params=2",
        },
        {
          name: "建筑业增值服务体系",
          bgUrl:
            "https://oss.yanxin7.cn/2023-08-24/aa6ce9d36b3da44fa6d7fe2706b842c9",
          url: "/subwebsite/ecosystem?params=3",
        },
      ],
      totalCompanyNum: 0,
      usableDate: [],
      dynamicCityName: "全国",
      dynamicCityName2: "全国",
      zZCirtyName: "全国",
      todayTotal: 0,
      totalView: 0,
      totalCompany: 0,
      activeTab: 0,
      menuList: [
        { label: "首页", name: "home" },
        { label: "招标投标", name: "personnel" },
        { label: "金融服务", name: "record" },
        { label: "企业查询", name: "quation" },
        { label: "机械管理", name: "project" },
        { label: "智慧工地", name: "creditscore" },
        { label: "工程咨询", name: "certificate" },
        { label: "CA认证", name: "CA" },
      ],
      proviceList: {
        福建: fujian,
        广西: guangxi,
        新疆: xinjiang,
        安徽: anhui,
        北京: beijing,
        重庆: chongqing,
        甘肃: gansu,
        广东: guangdong,
        贵州: guizhou,
        海南: hainan,
        河北: hebei,
        黑龙: heilongjiang,
        河南: henan,
        湖北: hubei,
        湖南: hunan,
        江苏: jiangsu,
        江西: jiangxi,
        吉林: jilin,
        辽宁: liaoning,
        内蒙: neimenggu,
        宁夏: ningxia,
        青海: qinghai,
        山东: shandong,
        上海: shanghai,
        陕西: shanxi1,
        山西: shanxi,
        台湾: taiwan,
        天津: tianjin,
        西藏: xizang,
        云南: yunnan,
        四川: sichuan,
        澳门: aomen,
        香港: xianggang,
        浙江: zhejiang,
        内蒙古: neimenggu,
        黑龙江: heilongjiang
      },
      mapCurProvice: "全国",
      searchShow: false,
      searchShow1: false,
      searchShow2: false,
      options: [],
      charts: null,
      value: "",
      geoMapType: 'china', // china:包含南海诸岛 中国：不包含

      selectValue: "全国",
      machineList: [],
      incrementData: [],
      dynamicDataList: [
        {
          name: "企业总理",
          unit: "ci",
          count: "226",
        },
        { name: "企业总理", unit: "ci", count: "4346" },
        { name: "企业总理", unit: "ci", count: "5426" },
        { name: "企业总理", unit: "ci", count: "436" },
      ],
      MembertData: [],
      seriesList: [
        {
          type: "effectScatter",
          coordinateSystem: "geo",
          rippleEffect: {
            brushType: "stroke", // 波纹的绘制方式，可选值有 'stroke' 和 'fill'
            period: 3, //动画时间，值越小速度越快
            scale: 3, //波纹圆环最大限制，值越大波纹越大
          },
          roam: false,
          data: [
            // {
            //   name: "邦建通科技集团股份有限公司(南宁)",
            //   value: [108.40322, 22.81395, 45], //y x
            // },
            // {
            //   name: "邦建通科技集团股份有限公司(厦门)",
            //   value: [118.14326, 24.50269, 45], //y x
            // },
          ],
        },
        {
          selectedMode: true, //取消地图区域点击事件
          geoIndex: 0, //将数据和第0个geo配置关联在一起
          type: "map",
        },
      ],
      yearVisitData: [],
    };
  },

  mounted() {
    this.getInfoV2();
    this.$nextTick(() => {
      document.title = getAppCustomInfo().title || '首页'
      this.getCompanyList();
      this.init();
    });
    this.$nextTick((res) => {
      setTimeout(() => {
        if (this.twoAddress[0].label !== "全国") {
          this.twoAddress.unshift({
            code: 2,
            label: "全国",
            latitude: 11,
            longitude: 11111,
            newChild: Array(0),
            padCode: null,
            pcode: 1,
            pname: null,
            value: 96851,
          });
        }
      }, 5000);
    });
    // this.urlBrowseEntStatTheme1()
  },
  methods: {
    init() {
      this.getTheme1();
      this.getTheme2();
      this.setMap(shengfen, 'all');
    },
    handleTabClick(tab) {
      let token = this.$store.state.account.token;
      console.log("tap------", tab);
      if (tab.name == "quation") {

        this.toPath("/dataCenter/dataCenter");
      }
      if (tab.name == "certificate") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/engineering/index");
      }

      if (tab.name == "personnel") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/recruit/index");
      }
      if (tab.name == "record") {
        if (window.location.origin.includes("localhost")) {
          window.open("https://dev.yanxin7.cn" + "/bh_web/#/")
          return
        }
        window.open(window.location.origin + "/bh_web/#/")
        return
      }

      let theUrlPrefix = "https://api.yanxin7.cn";
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      } else if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }

      if (tab.name == "project") {
        console.log("点击数字机械");
        const w = window.open("about:blank");
        // w.location.href = "https://www.bangjiantong.com/pc/mechanics.html";
        w.location.href =
          theUrlPrefix + `/erp/#/machine_login?token=${token}&loginType=7`;
      }
      if (tab.name == "creditscore") {
        console.log("点击智慧工地");
        // let url=`${window.location.origin}/erp/#/site_login?token=${token}&loginType=6`
        const w = window.open("about:blank");
        // w.location.href = theUrlPrefix + "/erp/#/smartSite/home";
        w.location.href =
          theUrlPrefix + `/erp/#/site_login?token=${token}&loginType=6`;
      }
    },
    async getInfoV2(e) {
      let params = e == "全国" ? "" : e;
      try {
        const { data } = await infoV2({
          province: params,
        });
        this.dynamicDataList = data.filter((item) => item.classify == 2);
        this.incrementData = data.filter((item) => item.classify == 1);
        this.MembertData = data.filter((item) => item.classify == 3);

        let totalCompany = data.filter((item) => item.name == "企业总量");
        this.totalCompanyNum = totalCompany[0].count;
        console.log("会员数据", this.MembertData);

        setTimeout(() => {
          this.$refs.dynamicDatas.initDynamicData();
          this.$refs.dynamicDatas2.initDynamicData();
        }, 1000);
      } catch (error) {
        console.log("获取动态数据报错", error);
      }
    },
    // async urlBrowseEntStatTheme1() {
    //   try {
    //     const { data } = await urlBrowseEntStatTheme1({})
    //     this.totalCompanyNum = data.total
    //     console.log('datatt', data)
    //   } catch (error) {

    //   }
    // },
    // 获取公司列表
    getCompanyList() {
      sysCompanyList({ current: 1, size: 1000, provinceName: "福建省" })
        .then((res) => {
          let { records } = res.data;
          let usableDate = records.filter((item) => item.y && item.x);
          this.usableDate = usableDate;
        })
        .catch((err) => {
          console.log("获取公司列表错误", err);
        });
    },
    getCurCityLCompanyListInfo(e) {
      let companyList = [];
      let currentProviceList = this.usableDate.filter((item) =>
        item.provinceName.includes(e.name)
      );
      currentProviceList.forEach((item) => {
        let params = {
          name: "",
          value: [0, 0, 45],
        };
        params.name = item.compName;
        params.value[0] = item.y;
        params.value[1] = item.x;
        companyList.push(params);
      });
      this.seriesList[0].data = companyList;
    },
    // 获取当日访问数据
    async getTheme1() {
      try {
        const { data } = await theme1({});
        this.todayTotal = data.todayTotal;
        this.totalView = data.total;
      } catch (error) {
        console.log("err", error);
      }
    },

    async getTheme2() {
      try {
        const { data } = await theme2({});
        this.totalCompany = data.total;
        this.companyData = data.classList
        setTimeout(() => {
          this.$refs.enterpriseData.initDynamicData();

        }, 3000);
      } catch (error) {
        console.log("err", error);
      }
    },
    // 初始化地图
    initCharts() {
      let _this = this;
      this.charts = echarts.init(this.$refs["charts"]);
      const option = {
        // 背景颜色
        backgroundColor: "#fff",
        // 提示浮窗样式
        tooltip: {
          show: true,
          trigger: "item", //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          alwaysShowContent: true,
          backgroundColor: "#fff",
          borderColor: "rgba(0, 0, 0, 0.16);",
          triggerOn: "mousemove",
          enterable: true, //鼠标是否可进入提示框浮层中
          textStyle: {
            fontSize: "12",
            overflow: "break",
          },
          formatter: function (params) {
            let str = "";
            // if(params.name == '广西' || params.name == '南宁市'){
            //     str='邦建通科技集团股份有限公司(南宁)'
            // }else{
            str = params.name;
            // }
            return str;
          },
        },
        visualMap: {
          //分段型视觉映射组件
          show: false, //
          type: "piecewise",
          left: 50,
          bottom: 50,
          showLabel: false,
          itemWidth: 10,
          itemHeight: 10,
          inverse: false,
          inRange: {
            color: ["#BBDFFF"], // 设置默认的分段颜色
          },
          // lt:小于; lte:小于等于; gt:大于; gte:大于等于;
          pieces: [
            {
              lte: 1,
              label: "",
              color: "#BBDFFF",
            },

            {
              gt: 30,
              label: ">30ms",
              color: "#5693F7",
            },
          ],
        },
        // 地图配置
        geo: {
          map: this.geoMapType,
          aspectScale: 0.75, //长宽比,0.75的含义为宽是高的0.75,假如高为100,宽就只有75;0.5的含义就是宽只有高的一半,假如高为100,宽就只有50
          zoom: 1.25, //视觉比例大小,1.2即为原有大小的1.2倍
          maxZoom: 2,
          roam: false, //是否开启鼠标缩放和平移漫游。默认不开启。可以不用设置,如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。
          top: "10%",
          scaleLimit: {
            //所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 1, //最小的缩放值
            max: 1.25, //最大的缩放值
          },
          // left:"45%",
          label: {
            // 通常状态下的样式
            normal: {
              show: false, // 不显示文字
              textStyle: {
                color: "#fff",
              },
            },
            // 鼠标放上去的样式
            emphasis: {
              textStyle: {
                color: "#fff",
              },
            },
          },
          // 地图区域的样式设置
          itemStyle: {
            // normal: {
            //   borderColor: "rgba(0, 0, 0, 0.1)",
            //   borderWidth: 1,
            // },
            areaColor: "#bde1ff",
            // 鼠标放上去高亮的样式
            emphasis: {
              // areaColor: "rgba(0, 0, 0, 0.1)",
              borderWidth: 0,
            },
          },

          // 隐藏南海诸多
          //   regions: [
          //   {
          //     name: "南海诸岛",
          //     itemStyle: {
          //       show:false,
          //       // 隐藏地图
          //       normal: {
          //         opacity: 0, // 为 0 时不绘制该图形
          //       }
          //     },
          //     label: {
          //       show: false // 隐藏文字
          //     }
          //   }
          // ]
        },
        series: _this.seriesList,
      };
      var timeFn = null;
      this.charts.setOption(option);
      // 鼠标移入
      this.charts.on("mouseover", function () {
        //取消鼠标移入地图区域高亮
        // _this.charts.dispatchAction({
        //   type: "legendUnSelect",
        // });
      });
      // 单击
      this.charts.on("click", function (e) {
        _this.$message({
          message: "双击地图可返回上一级",
          type: "success",
        });
        //鼠标点击取消高亮
        _this.charts.dispatchAction({
          type: "legendUnSelect",
        });
        clearTimeout(timeFn);
        timeFn = setTimeout(function () {
          _this.getCurCityLCompanyListInfo(e);
          console.log("adCodeadCode", e.name);
          let targetName = e.name || "";
          if (targetName) {
            _this.getAdcode(targetName);
            _this.getInfoV2(targetName);
            _this.setMap(_this.proviceList[targetName]);
            _this.mapCurProvice = targetName;
            _this.charts.off("click");
            console.log("有数据");
          } else {
            console.log("没有有数据");
            // _this.$message({
            //   message: "没有下一级了",
            //   type: "error",
            // });
          }
        }, 250);
      });

      // 双击
      this.charts.on("dblclick", function () {
        clearTimeout(timeFn);
        location.reload(); // 地图多次渲染未解决，先强制刷新页面
        _this.charts.clear();
        _this.setMap(shengfen, 'all');
        _this.mapCurProvice = "全国";
      });
    },
    getAdcode(e) {
      let adcodeItem = this.twoAddress.filter((item) => {
        return item.label.includes(e);
      });
      console.log("adcodeItem", adcodeItem);
      if (adcodeItem.length > 0) {
        this.$refs.personDta.getPersonNum(adcodeItem[0].value);
        this.$refs.biddingDatas.biddingCount(adcodeItem[0].value);
      }
    },
    leftDynamicItem(e) {
      this.getInfoV2(e);
    },
    setMap(areaJson, areaType = '') {
      if (this.charts) {
        this.charts.clear();
        this.charts.off("click");
      } else {
      }
      // 为 china 时会显示南海诸岛
      this.geoMapType = 'all' == areaType ? 'china' : '中国',
        echarts.registerMap(this.geoMapType, areaJson);
      console.log("重新刷新地图");
      this.initCharts();
    },

    // 切换省份
    handMap(e) {
      console.log("地图选中省市", e);
      if (e == "全国") return location.reload();
      if (!e) return;
      let proviceItem = this.twoAddress.filter((item) => item.label == e);
      this.$refs.personDta.getPersonNum(proviceItem[0]);
      this.$refs.biddingDatas.biddingCount(proviceItem[0]);
      console.log("proviceItem", proviceItem);
      this.getInfoV2(e);
      let provice = e.substring(0, 2);
      this.getCurCityLCompanyListInfo({ name: provice });
      if (this.proviceList[provice]) {
        this.setMap(this.proviceList[provice]);
        this.charts.off("click");
      } else {
        this.$message({
          message: "该区域没有数据",
          type: "error",
        });
      }
    },
    // 清空全国数据dynamicCityName
    ClearDyCity() {
      this.dynamicCityName = "全国";
    },
    // 清空全国数据
    ClearZZCity() {
      this.zZCirtyName = "全国";
    },
    ClearMapCity() {
      this.mapCurProvice = "全国";
      location.reload(); // 地图多次渲染未解决，先强制刷新页面
    },
    handSystemItem(item, index) {
      return // 故意无法点击 ----20241025 liyibiao

      if (!this.token) {
        this.showLoginForm = true;
        return;
      }
      this.isActive = index;
      let vRouter = this.$router.resolve({
        path: item.url,
        params: {},
      });
      window.open(vRouter.href, "_blank");
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      // this.$router.replace(toPage);
      console.log("handleLoginSuccess-->toPage=" + redirectPath);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      //   this.activeTab = "websiteIndex";
      //   if (this.redirectPath != "/") {
      //     this.redirectPath = "/websiteIndex";
      //     this.$router.push({ path: this.redirectPath });
      //   }
    },
  },
};
</script>
<style>
.select-city {
  position: absolute;
  z-index: 9;
  margin-top: 40px;
  transform: translateX(-50%);
  /* 修改下拉箭头的颜色和大小 */

  border-radius: 18rpx;
  /* overflow: hidden; */
}

.select-city .el-input--mini .el-input__inner {
  height: 28px;
  line-height: 28px;
  border-radius: 18px;
}

.el-select .el-input {
  width: 150px;
}
</style>
<style scoped lang="less">
/* 头部背景图 */

.main-box {
  min-width: 1520px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /*overflow: hidden;*/
}

.data-center {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 60px;
  width: 1520px;
  box-sizing: border-box;
  overflow: hidden;
}

.new-data {
  width: 320px;
  height: 244px;
  opacity: 1;
  border: 1px solid #bbdfff;
  position: relative;
}

.member-num {
  position: absolute;
  bottom: 10px;
  left: 40px;
  font-weight: bolder;
}

.map-box {
  position: relative;
}

.map-data {
  margin-top: 170px;
  width: 722px;

  position: relative;
  margin-bottom: 150px;
}

.second-page {
  width: 1520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 900px;
  background: #f9fcff;
  margin: 20px auto 0;
}

.second-header {
  width: 100%;
  height: 60px;
  background-image: url("https://oss.yanxin7.cn/2023-09-01/08705eab9985e58807c06dbe1b95fabe");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  line-height: 60px;
  text-align: center;
  color: #2288fc;
  font-size: 30px;
}

.flex-row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.second-left {
  width: 454px;
  height: 200px;
  border-radius: 4px 4px 4px 4px;
  background-color: rgba(205, 221, 243, 0.2);
  border: 1px solid #ffffff;
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
}

.box1 {
  cursor: pointer;
  min-width: 164px;
  height: 200px;
  background-image: url("https://oss.yanxin7.cn/2023-09-01/dea792a7d85bdc9995f93b6bd47a72c7");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 20px;
  transition: all 0.3s;
  padding-top: 24px;
  text-align: center;
  font-size: 24px;
  letter-spacing: 3px;
}

.box1:hover {
  transform: translateY(-20px);
  background-image: url("https://oss.yanxin7.cn/2023-09-01/61bcee2a72b435c44221b1b8d70cbada");
}

.box2:hover {
  transform: translateY(-20px);
  background-image: url("https://oss.yanxin7.cn/2023-09-01/f9df5f975e736d5768ca6f1430205b27");
}

.box3:hover {
  transform: translateY(-20px);
  background-image: url("https://oss.yanxin7.cn/2023-09-01/276e5c0d42b39633b282086faca53c35");
}

.box2 {
  cursor: pointer;
  min-width: 164px;
  height: 200px;
  background-image: url("https://oss.yanxin7.cn/2023-09-01/d02bffb77546213a9cb26b41bbf03662");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 20px;
  transition: all 0.3s;
  padding-top: 24px;
  text-align: center;
  letter-spacing: 3px;
  font-size: 24px;
}

.box3 {
  cursor: pointer;
  transition: all 0.3s;
  min-width: 164px;
  height: 200px;
  background-image: url("https://oss.yanxin7.cn/2023-09-01/f138fd55915fe16355b565febe7eae8d");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 24px;
  text-align: center;
  letter-spacing: 3px;
  font-size: 24px;
}

.mb-10 {
  margin-bottom: 10px;
}

.second-title {
  font-size: 20px;
  color: #212121;
  text-align: left;
}

.second-sub {
  color: #888888;
  font-size: 16px;
  text-align: left;
}

.fz-12 {
  font-size: 12px;
  margin-top: 15px;
  margin-left: 20px;
}

.second-content {
  padding: 40px 20px;
  box-sizing: border-box;
  width: 1520px;
}

.mb-20 {
  margin-bottom: 20px;
}

.second-right {
  width: 600px;
  height: 859px;
  padding: 20px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.second-right-box {
  width: 560px;
  height: 829px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  overflow-x: hidden;
}

.second-right::-webkit-scrollbar {
  width: 1px;
  /* 滚动条宽度 */
}

.second-right:hover {}

.c-yellow {
  color: #ffd339;
  font-weight: bolder;
}

.machine-item {
  width: 517px;
  height: 40px;
  background: rgba(124, 169, 231, 0.3);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.machine-item:nth-child(odd) {
  background: none;
}

.machine-item:hover {
  color: #2288fc;
  background-color: white;
}

/* 修改竖直滚动条样式 */
::-webkit-scrollbar {
  width: 4px !important;
  /* 滚动条宽度 */
}

/* 竖直滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  /* 轨道背景色 */
  border-radius: 4px;
  /* 滑块圆角 */
}

/* 竖直滚动条轨道 */
::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.24);
}

.add-data {
  width: 745px;
  height: 1030px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
  background-color: #2e8dfb;
}

.data-header {
  text-align: center;
  justify-content: center;
  width: 745px;
  height: 120px;
  background: rgba(250, 250, 250, 0.34);
  border-radius: 10px 10px 0px 0px;
  background-image: url("https://oss.yanxin7.cn/2023-07-05/d520046b09c8e046d561e214c4c4d3a2");
  background-repeat: no-repeat;
  background-size: 204px 116px;
  background-position: 396px 0;
  display: flex;
  padding-top: 36px;
  padding-left: 20px;
  position: relative;
}

.second-header-data::before {
  content: ".";
  width: 4px;
  height: 20px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-right: 20px;
  margin-top: 13px;
}

.little-quanguo {
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50px;
  right: 35px;
}

.data-boxs {
  width: 745px;
  overflow: auto;
  height: 900px;
  color: white;
}

.data-item {
  height: 50px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 40px;
}

.b-line {
  margin: 0 20px;
  border-bottom: 1px solid rgb(247, 227, 227);
}

.data-item:last-child {
  border-bottom: none;
}

.flex-center {
  display: flex;
  align-items: center;
}

.add-icon {
  width: 8px;
  height: 8px;
  background: #ffd339;
  opacity: 1;
  border-radius: 50%;
  margin-right: 20px;
}

.data-item:hover {
  background-color: #2288fc;
}

.align-center {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 25px;
  margin-top: -30px;
}

.align-center2 {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 15px;
  font-weight: bolder;
  letter-spacing: 3px;
}

.menu-item .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: rgba(112, 154, 233, 0.3) !important;
}

::v-deep #content.el-tabs--border-card {
  border: none;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  background-color: #0460e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  color: white;
  background-color: #0662e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  margin-top: 0px;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  height: 40px;
}

::v-deep .el-tabs__nav.is-stretch>* {
  flex: 1;
  text-align: center;
  width: 170px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  font-weight: bold;
}

.min-w {
  min-width: 1320px;
}

::v-deep .el-header {
  padding: 10px 0px 0px 0px;
}

/* 菜单箭头 */
::v-deep .el-tabs__nav-next,
::v-deep .el-tabs__nav-prev {
  position: absolute;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  color: #ffffff;
}

::v-deep .el-tabs__nav-next.is-disabled,
::v-deep .el-tabs__nav-prev.is-disabled {
  color: #ffffff;
}

::v-deep .el-tabs--border-card>.el-tabs__header {
  /* background-color: #F5F7FA; */
  /* border-bottom: 1px solid #E4E7ED; */
  margin: 0;
}

::v-deep .el-tabs--bottom .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: 0px;
}

.menu-item .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: rgba(112, 154, 233, 0.3) !important;
}

.el-carousel__button {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.info-left_video .el-carousel__arrow {
  font-size: 30px !important;
  background-color: rgba(31, 45, 61, 0.1);
  color: white;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header {
  background-color: transparent;
}

::v-deep #content.el-tabs--border-card {
  border: none;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  color: white;
  background-color: #0662e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  margin-top: 0px;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  height: 40px;
}

::v-deep .el-tabs__nav.is-stretch>* {
  flex: 1;
  text-align: center;
  width: 170px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  font-weight: bold;
}

.nav-width {
  height: 40px;
  width: 100%;
  margin: 0 auto;
  background-color: #013c8233;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  background-color: transparent;
}

.el-tabs--border-card {
  background: transparent;
  box-shadow: none;
}

.system-list {
  width: 1520px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 220px;
  overflow: hidden;

}

.system-item {
  cursor: pointer;
  width: 370px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 3px 20px 1px rgba(183, 183, 183, 0.16);
  border-radius: 2px 2px 2px 2px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.item-text {
  font-size: 26px;
  width: 370px;
  height: 80px;
  line-height: 80px;
  background-color: rgba(138, 133, 133, 0.2);
  border-radius: 2px 2px 2px 2px;
  color: white;
}

.system-item:hover {
  width: 375px;
  height: 205px;
  scale: 1.05;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: white !important;
  color: #007ef2 !important;
}

.is_active {
  color: #2288fc;
}

.enterprise-num {
  width: 532px;
  height: 560px;
  background-image: url("https://oss.yanxin7.cn/2023-09-01/d2340f4a5b33d631f547f68e2bacee9b");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 120px;
}

.line-height20 {
  margin-bottom: 20px;
}

.enterprise-nums {
  color: #2288fc;
}

.fz-50 {
  font-size: 50px;
  margin-right: 10px;
}

.fz-30 {
  font-size: 30px;
}
</style>

