<template>
  <div class="main-header">
    <div class="search-for"></div>
    <!-- <div class="nav-width">
      <div class="nav-left">
        <el-tabs class="menu-item" id="content" type="border-card" stretch v-model="activeTab"
          @tab-click="handleTabClick">
          <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in menuList" :key="index">
          </el-tab-pane>
        </el-tabs>
      </div>
    </div> -->

    <login-form v-if="showLoginForm" :routeRedirect="routeRedirect" @loginSuccess="handleLoginSuccess"
      @close="handleLoginClose" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import LoginForm from "@/components/login-form";
export default {
  data() {
    return {
      showLoginForm: false,
      activeTab: 'home',
      menuList: [
        { label: "首页", name: "home" },
        { label: "招标投标", name: "personnel" },
        { label: "金融服务", name: "record" },
        { label: "企业查询", name: "quation" },
        { label: "机械管理", name: "project" },
        { label: "智慧工地", name: "creditscore" },
        { label: "工程咨询", name: "certificate" },
        { label: "CA认证", name: "CA" },
        { label: "电子合同", name: "contract" },
      ],
    };
  },
  components: {
    LoginForm,
  },
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
    isSettleCompany() {
      if (this.userInfos?.companyName) {
        return true
      }
      return false
    },
  },
  methods: {
    handleTabClick(tab, oldName) {
      let activeName = tab.name
      console.log("activeName", activeName)
      let token = this.$store.state.account.token;
      if (activeName == "quation") {
        if (!token) {
          this.showLoginForm = true;
          return false
        }
        this.toPath("/enterprise/home");
      }

      if (activeName == "certificate") {
        if (!token) {
          this.showLoginForm = true;
          return false
        }
        this.toPath("/engineering/index");
      }
      if (activeName == "personnel") {
        if (!token) {
          this.showLoginForm = true;
          return false
        }
        this.toPath("/recruit/index");
      }

      if (activeName == "record") {
        if (window.location.origin.includes("localhost")) {
          window.open("https://dev.yanxin7.cn" + "/bh_web/#/")
          return
        }
        window.open(window.location.origin + "/bh_web/#/")
        return
      }
      let theUrlPrefix = "https://www.bangjiantong.com";
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      } else if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }
      if (activeName == "project") {
        if (!token) {
          window.open(`${theUrlPrefix}/erp/#/machine_login`, activeName)
          return false
        }
        window.open(`${theUrlPrefix}/erp/#/machine_login?token=${token}&loginType=7`, activeName)
        return false

      }


      if (activeName == "contract") {
          window.open(`https://contract.bangjiantong.com/contract?ref=addtabs`, 'contract')
          return false
      }


      if (activeName == "creditscore") {
        if (!token) {
          window.open(`${theUrlPrefix}/erp/#/site_login`, activeName)
          return false
        }
        window.open(`${theUrlPrefix}/erp/#/site_login?token=${token}&loginType=6`, activeName)
        return false

      }
      if (activeName == "CA") {
        if (!this.isSettleCompany && this.isLoggedIn) {
          this.$message.warning("请先注册企业")
          setTimeout(() => {
            this.$router.push('/enterCompany/enterCompany')
          }, 3000);
          return false
        }
        if (!token) {
          this.showLoginForm = true;
          return false
        }
        window.open(`${theUrlPrefix}/bxbh/#/CA/apply?params=website`, activeName)
        return false
      }
      return true
    },

    toPath(url) {
      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      window.open(vRouter.href, "name");
    },
    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      // this.$router.replace(toPage);
      console.log("handleLoginSuccess-->toPage=" + redirectPath);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      this.activeTab = "websiteIndex";
      if (this.redirectPath != "/") {
        this.redirectPath = "/dataCenter/dataCenter";
        this.$router.push({ path: this.redirectPath });
      }
    },
  },
};
</script>
<style>
.el-tabs__nav .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: #1890FF !important;
  opacity: .8;
}
</style>
<style scoped lang="less">
/* 头部背景图 */
.main-header {
  width: 100%;
  // background-image: url(~@/assets/images/bjtsy/hb1.png);
  background-image: url("https://oss.yanxin7.cn/2023-07-04/2c735de815fc229cb37801d40cbee557");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 1;
}

.search-for {
  width: 100%;
  height: 330px !important;
  color: white;
  text-align: center;
}

.main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}

.nav-bar-header {
  width: 100%;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(3, 96, 230, 0.06);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding-left: 200px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.data-center {
  display: flex;
  justify-content: space-between;
  margin-bottom: 97px;
  width: 1520px;
}

.new-data {
  width: 320px;
  height: 244px;
  opacity: 1;
  border: 1px solid #bbdfff;
  position: relative;
}

.member-num {
  position: absolute;
  bottom: 10px;
  left: 40px;
  font-weight: bolder;
}

.map-box {
  position: relative;
}

.map-data {
  margin-top: 170px;
  width: 722px;

  position: relative;
  margin-bottom: 150px;
}

.nav-left {
  width: 1520px;
  margin: 0 auto;
}

/* 浮窗样式 */
.suspended_box {
  position: fixed;
  bottom: 400px;
  z-index: 20;
  right: 20px;
  font-size: 16px;
  width: 80px;
  height: 222px;
}

.kefu {
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 3px 16px 1px rgba(41, 96, 222, 0.16);
  position: relative;
  box-sizing: border-box;
  width: 80px;
  height: 202px;
  border-radius: 16px;
  padding: 20px 10px;
}

.qrcode_content {
  position: absolute;
  bottom: 32%;
  z-index: 20;
  right: 100px;
  display: none;
}

.business_content {
  position: absolute;
  bottom: -35%;
  z-index: 20;
  right: 100px;
  display: none;
}

.pp {
  width: 165px;
  height: 165px;
  margin-top: 19px;
  margin-left: 17px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

.to_top {
  width: 80px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 3px 16px 1px rgba(41, 96, 222, 0.16);
  border-radius: 6px;
  padding-top: 14px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #333e4d;
  cursor: pointer;
}

.to_top_img {
  width: 80px !important;
  /* height: 60px !important; */
}

.to_top_img img {
  width: 30px !important;
  height: 30px !important;
}

.qr_1 {
  width: 60px;
  height: 75px;
}

.pt-22 {
  padding-top: 11px;
}

.qr_1:hover {
  background-color: #eff2f8;
  border-radius: 6px 6px;
}

.qr_1:hover+.qrcode_content {
  display: block;
}

.qr_code {
  width: 30px;
  height: 30px;
  background: url("https://oss.yanxin7.cn/2023-07-03/0b35896e890f7560f862eff53ecb127e") no-repeat;
  background-size: cover;
  margin-bottom: 1px;
  /* margin-top: 20px; */
  margin-left: 15px;
  cursor: pointer;
  /* position:relative */
}

.qr_code3 {
  width: 30px;
  height: 30px;
  background: url("https://oss.yanxin7.cn/2023-07-03/92b14e7bb43b9d363583f709984b969d") no-repeat;
  background-size: cover;
  margin-bottom: 1px;
  margin-left: 15px;
  cursor: pointer;
}

.code_c {
  font-size: 14px;
  text-align: center;
}

.erweima {
  text-align: center;
  width: 200px;
  height: 240px;
  top: -7px;
  /* left: -100px; */
  background: #fff;
  /* border: 1px solid #CCC; */
  float: right;
  cursor: default !important;
  box-shadow: 0px 3px 16px 1px rgba(41, 96, 222, 0.16);
  border-radius: 6px 6px;
}

.qr_2 {
  width: 60px;
  height: 75px;
}

.qr_2:hover {
  background-color: #eff2f8;
  border-radius: 6px 6px;
}

.qr_2:hover+.gzh_content {
  display: block;
}

.qr_3:hover {
  background-color: #eff2f8;
  border-radius: 6px 6px;
}

.qr_3:hover+.business_content {
  display: block;
}

.qr_3 {
  width: 60px;
  height: 75px;
}

.msg {
  text-align: left;
  margin-left: 32px;
  margin-top: 40px;
  font-size: 16px;
  color: #5c6681;
}

::v-deep .el-backtop {
  width: 20px;
}

.msg_2 {
  width: 150px;
  text-align: left;
  margin-left: 32px;
  margin-top: 8px;
  font-weight: bold;
  font-size: 18px;
}

.fw-b {
  font-weight: bold;
}

.fz-19 {
  font-size: 19px;
}

.main_search_scheme_input_l_select {
  padding: 0 10px;
  min-width: 120px;
  height: 30px;
  background: #ffffff;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  border: 1px solid #e2e5ec;
  display: flex;
  align-items: center;
  justify-content: center;
}

.three-page {
  width: 100vw;
  height: 1080px;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("https://oss.yanxin7.cn/2023-07-05/c67dabd88a5a37a0cc7049a2832760ee");
  background-repeat: no-repeat;
}

.second-page {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second-header {
  display: flex;
  justify-content: center;
  margin-bottom: 54px;
  color: white;
  text-align: center;
}

.second-title {
  font-weight: bolder;
  font-size: 32px;
  text-align: center;
}

.fz-12 {
  font-size: 12px;
  margin-top: 15px;
  margin-left: 20px;
}

.second-content {
  width: 1520px;
  display: flex;
  justify-content: space-between;
}

.second-left {
  width: 425px;
  height: 859px;
  margin-right: 125px;
  background-image: url("https://oss.yanxin7.cn/2023-07-05/41e5b471652eaa689d3a855314108739");
  background-repeat: no-repeat;
  background-size: 425px 859px;
}

iframe {
  border: none;
  border-radius: 50px;
  width: 382px;
  height: 835px;
  padding-top: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.second-right {
  width: 600px;
  height: 859px;
  padding: 20px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.second-right-box {
  width: 560px;
  height: 829px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  overflow-x: hidden;
}

.second-right::-webkit-scrollbar {
  width: 1px;
  /* 滚动条宽度 */
}

.second-right:hover {}

.c-yellow {
  color: #ffd339;
  font-weight: bolder;
}

.machine-item {
  width: 517px;
  height: 40px;
  background: rgba(124, 169, 231, 0.3);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.machine-item:nth-child(odd) {
  background: none;
}

.machine-item:hover {
  color: #2288fc;
  background-color: white;
}

/* 修改竖直滚动条样式 */
::-webkit-scrollbar {
  width: 4px !important;
  /* 滚动条宽度 */
}

/* 竖直滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  /* 轨道背景色 */
  border-radius: 4px;
  /* 滑块圆角 */
}

/* 竖直滚动条轨道 */
::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.24);
}

.add-data {
  width: 745px;
  height: 1030px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
  background-color: #2e8dfb;
}

.data-header {
  text-align: center;
  justify-content: center;
  width: 745px;
  height: 120px;
  background: rgba(250, 250, 250, 0.34);
  border-radius: 10px 10px 0px 0px;
  background-image: url("https://oss.yanxin7.cn/2023-07-05/d520046b09c8e046d561e214c4c4d3a2");
  background-repeat: no-repeat;
  background-size: 204px 116px;
  background-position: 396px 0;
  display: flex;
  padding-top: 36px;
  padding-left: 20px;
  position: relative;
}

.second-header-data::before {
  content: ".";
  width: 4px;
  height: 20px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-right: 20px;
  margin-top: 13px;
}

.little-quanguo {
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50px;
  right: 35px;
}

.data-boxs {
  width: 745px;
  overflow: auto;
  height: 900px;
  color: white;
}

.data-item {
  height: 50px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 40px;
}

.b-line {
  margin: 0 20px;
  border-bottom: 1px solid rgb(247, 227, 227);
}

.data-item:last-child {
  border-bottom: none;
}

.flex-center {
  display: flex;
  align-items: center;
}

.add-icon {
  width: 8px;
  height: 8px;
  background: #ffd339;
  opacity: 1;
  border-radius: 50%;
  margin-right: 20px;
}

.data-item:hover {
  background-color: #2288fc;
}

.align-center {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 25px;
}
.menu-item{
  overflow: auto;
}
.menu-item .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: rgba(112, 154, 233, 0.3) !important;
}

::v-deep #content.el-tabs--border-card {
  border: none;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  background-color: #0460e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  color: white;
  background-color: #0662e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  margin-top: 0px;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  height: 40px;
}

::v-deep .el-tabs__nav.is-stretch>* {
  flex: 1;
  text-align: center;
  width: 150px !important;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  // border: 1px solid red;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: white !important;
  font-weight: bold;
  color: #007ef2 !important;
}

::v-deep .tabs-card.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: white !important;
  color: #007EF2 !important;
}

.min-w {
  min-width: 1320px;
}

::v-deep .el-header {
  padding: 10px 0px 0px 0px;
}

/* 菜单箭头 */
::v-deep .el-tabs__nav-next,
::v-deep .el-tabs__nav-prev {
  position: absolute;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  color: #ffffff;
}

::v-deep .el-tabs__nav-next.is-disabled,
::v-deep .el-tabs__nav-prev.is-disabled {
  color: #ffffff;
}

::v-deep .el-tabs--border-card>.el-tabs__header {
  /* background-color: #F5F7FA; */
  /* border-bottom: 1px solid #E4E7ED; */
  margin: 0;
}

::v-deep .el-tabs--bottom .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: 0px;
}


.el-carousel__button {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.info-left_video .el-carousel__arrow {
  font-size: 30px !important;
  background-color: rgba(31, 45, 61, 0.1);
  color: white;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header {
  background-color: transparent;
}

::v-deep #content.el-tabs--border-card {
  border: none;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  color: white;
  background-color: #0662e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  margin-top: 0px;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  height: 40px;
}

::v-deep .el-tabs__nav.is-stretch>* {
  flex: 1;
  text-align: center;
  width: 170px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  font-weight: bold;
}

.nav-width {
  height: 40px;
  width: 100%;
  margin: 0 auto;
  background-color: #013c8233;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  background-color: transparent;
}

.el-tabs--border-card {
  background: transparent;
  box-shadow: none;
}

.system-list {
  width: 1520px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.system-item {
  cursor: pointer;
  width: 370px;
  height: 250px;
  background: #ffffff;
  box-shadow: 0px 3px 20px 1px rgba(183, 183, 183, 0.16);
  border-radius: 2px 2px 2px 2px;
  margin-bottom: 40px;
}

.item-text {
  font-size: 26px;
  line-height: 80px;
}

.is_active {
  color: #2288fc;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: white !important;
  color: #007ef2 !important;
}
</style>

